<template>
	<ButtonGeneric
		:id="id"
		class="bg-transparent text-white border-2 hover:bg-white hover:text-black"
		@clicked="clicked">
		<slot />
	</ButtonGeneric>
</template>

<script setup>
	const { id } = defineProps({
		id: {
			type: String,
			default: "",
		},
	})

	const emits = defineEmits(["clicked"])
	const clicked = () => {
		emits("clicked", { id: id })
	}
</script>

<style lang="scss"></style>
