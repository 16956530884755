<template>
	<HeaderTestSiteWarning />
	<HeaderSiteNotification />

	<HeaderBasicHeader />

	<!-- Page content -->
	<slot />

	<FooterBasicFooter />
</template>

<script setup>
	useHead(() => {
		const runtimeConfig = useRuntimeConfig()

		//Prefix all page titles with the event name
		return {
			titleTemplate: (pageTitle) => {
				return pageTitle ? `${runtimeConfig.public.EVENT_NAME} - ${pageTitle}` : `${runtimeConfig.public.EVENT_NAME}`
			},
		}
	})
</script>

<style scoped></style>
